import axios from "axios";
import { parseFunctions } from "./core/utils/parsers";
import { evalFieldExpression } from "./core/utils/expressions";
import { Map } from "./core/fields";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

export const DocumentPrint = (): JSX.Element => {
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const version = searchParams.get("version");

  const [documentPrint, setDocumentPrint] = useState<DocumentLayout>();
  const [status, setStatus] = useState<any>({});
  const [context, setContext] = useState<any>({});
  const [general, setGeneral] = useState<any>({});
  const [isQRCodeLoaded, setQRCodeLoaded] = useState(false);
  const [isLogoLoaded, setLogoLoaded] = useState(false);

  const fetchSubject = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACK_END_API}/protocols/api/${id}${
          version ? `/version/${version}` : ""
        }`,
        {
          headers: {
            apiKey: localStorage.getItem("apiKey"),
          },
        }
      );

      delete response.data.id;

      setDocumentPrint(response.data.document);
      setStatus(version ? "APOSTILLED" : response.data.status);
      setContext(response.data.protocol);
      setGeneral({
        acceptances: response.data.acceptances,
        $environment: response.data.environment,
        $library: parseFunctions(response.data.function ?? {}),
        $history: response.data.diff,
        $commits: response.data.versions,
      });
    } catch (e) {}
  };

  useEffect(() => {
    fetchSubject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAllLoaded = isQRCodeLoaded && isLogoLoaded;

  const pdfPageStyle: React.CSSProperties = {
    minWidth: "794px",
    minHeight: "1123px",
    overflow: "auto",
    boxSizing: "border-box",
    backgroundColor: "white",
  };

  const watermarkStyle: React.CSSProperties = {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    pointerEvents: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "5em",
    color: "rgba(200, 200, 200, 0.5)",
    transform: "rotate(-45deg)",
    zIndex: 9999,
  };

  const element = document.querySelector("div.enabled");

  if (element) {
    element.parentNode?.removeChild(element);
  }

  const PROTOCOL_STATUS_WATERMARK: any = {
    WAITING_ACCEPTANCE: "AGUARDANDO ACEITE",
    WAITING_TAX_PAYMENT: "AGUARDANDO PAGAMENTO",
    APOSTILLED: "APOSTILADO",
  };

  if (documentPrint) {
    return (
      <div id={isAllLoaded ? "waitNoMore" : undefined} style={pdfPageStyle}>
        <div style={watermarkStyle}>
          {PROTOCOL_STATUS_WATERMARK[status] ?? ""}
        </div>

        <header className="flex items-center space-y-4 p-6">
          <div>
            <img
              src={documentPrint.logo}
              width="125px"
              alt="city logo"
              onLoad={() => setLogoLoaded(true)}
            />
          </div>
          <div
            className="flex flex-col text-center mx-auto"
            style={{ maxWidth: "300px" }}
          >
            <span className="font-black text-xl">
              {documentPrint.headerTitle}
            </span>
            <span dangerouslySetInnerHTML={{__html: documentPrint.headerDescription}}></span>
          </div>
          <div>
            <img
              src={documentPrint.qrcode}
              width="100px"
              alt="qrcode to document"
              onLoad={() => setQRCodeLoaded(true)}
            />
          </div>
        </header>

        <main className="flex flex-col space-y-4 px-6">
          <div className="flex flex-col justify-center text-center font-bold text-lg">
            <span>{documentPrint.title}</span>
            <span>{documentPrint.description}</span>
          </div>

          <div>
            {documentPrint &&
              documentPrint.blocks.map((block, index) => (
                <>
                  <div className="flex items-center space-x-4 font-bold mb-2.5">
                    {block.label}
                  </div>
                  <div className="flex flex-col border">
                    {block?.rows.map((row, rowIndex) => (
                      <div
                        key={"row-" + rowIndex}
                        className="flex items-center space-x-4"
                      >
                        <div className="flex w-full">
                          {row.map((col) => {
                            return (
                              <div className="flex w-full border">
                                <div className="flex flex-col p-2.5">
                                  <label className="text-sm text-gray-600">
                                    {col.label}
                                  </label>
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: evalFieldExpression(
                                        `\`${col.value}\``,
                                        context,
                                        general,
                                        {}
                                      ),
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ))}
                  </div>
                  {block.map?.source && (
                    <div className="flex justify-center pt-6">
                      <Map
                        key={"map"}
                        options={{
                          label: "",
                          layers: block.map.layers,
                          width: block.map.width ?? "300px",
                          height: block.map.height ?? "300px",
                          table: false,
                          zoomControl: false,
                          hideHeader: true,
                        }}
                        value={evalFieldExpression(
                          block.map.source,
                          context,
                          general,
                          {}
                        )}
                      />
                    </div>
                  )}
                </>
              ))}
          </div>
        </main>
      </div>
    );
  } else {
    return <></>;
  }
};
